<template>
  <div class="page">
    <div class="title">
      <span>企业基础认证</span>
      <span @click="userIdentity"
      style="cursor: pointer;"
        >切换到个人认证
        <i class="el-icon-caret-right"></i>
      </span>
    </div>
    <div class="content">
      <div class="content-title">
        {{ steps == 1 ? "企业信息" : "法人信息" }}
      </div>
      <!-- 表单 -->
      <div class="form">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
          label-position="top"
        >
          <template v-if="steps == 1">
            <el-form-item label="企业类型" prop="type">
              <el-radio-group v-model="ruleForm.type">
                <el-radio
                  :label="item.value"
                  v-for="(item, index) in companyType"
                  :key="index"
                >
                  {{ item.name }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="企业证书类型" prop="certificateType">
              <el-select
                v-model="ruleForm.certificateType"
                placeholder="请选择"
              >
                <el-option
                  :label="item.name"
                  :value="item.value"
                  v-for="(item, index) in certificateType"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="企业证件上传">
              <el-upload
                :auto-upload="true"
                class="avatar-uploader"
                :action="upActoin"
                :show-file-list="false"
                :data="{ attachmentObjectTypeCode: 'chinaumsFiles' }"
                :headers="upHeaders"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="企业注册省市区">
              <el-cascader
                placeholder="支持关键字搜索"
                :options="options"
                v-model="bankAreaCode"
                filterable
              >
              </el-cascader>
            </el-form-item>
            <el-form-item label="企业注册区域" prop="registerArea">
              <el-input
                v-model="ruleForm.registerArea"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="企业注册名称" prop="registerName">
              <el-input
                v-model="ruleForm.registerName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="公司注册编号/统一社会信用代码"
              prop="registerNo"
            >
              <el-input
                v-model="ruleForm.registerNo"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="公司营业地址（详细到街道、房间号码）"
              prop="businessAddress"
            >
              <el-input
                v-model="ruleForm.businessAddress"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="主营业务/业务范围" prop="businessScope">
              <el-input
                v-model="ruleForm.businessScope"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="企业邮箱" prop="companyEmail">
              <el-input
                v-model="ruleForm.companyEmail"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="固定电话" prop="companyPhone">
              <el-input
                v-model="ruleForm.companyPhone"
                placeholder="请输入"
              ></el-input>
              <!-- <div style="display: flex;">
								<el-input v-model="ruleForm.name" placeholder="区号" style="width: 180px;"></el-input>
								<span style="padding: 0 10px;">-</span>
								<el-input v-model="ruleForm.companyPhone" placeholder="请输入"></el-input>
							</div> -->
            </el-form-item>
          </template>
          <template v-if="steps == 2">
            <div>
              <el-form-item label="法人姓名" prop="legalName">
                <el-input
                  v-model="ruleForm.legalName"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item label="法人身份证号" prop="idCardNo">
                <el-input
                  v-model="ruleForm.idCardNo"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item label="身份证有效期" prop="effectiveDate">
                <el-date-picker
                  :value-format="'yyyy-MM-dd'"
                  v-model="ruleForm.effectiveDate"
                  type="date"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="身份证正面照片上传">
                <el-upload
                  :auto-upload="true"
                  class="avatar-uploader"
                  :action="upActoin"
                  :show-file-list="false"
                  :data="{ attachmentObjectTypeCode: 'chinaumsFiles' }"
                  :headers="upHeaders"
                  :on-success="idFhandleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="imageUrl" :src="idFimageUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="身份证反面照片上传">
                <el-upload
                  :auto-upload="true"
                  class="avatar-uploader"
                  :action="upActoin"
                  :show-file-list="false"
                  :data="{ attachmentObjectTypeCode: 'chinaumsFiles' }"
                  :headers="upHeaders"
                  :on-success="idBhandleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="imageUrl" :src="idBimageUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="联系地址" prop="legalAddress">
                <el-input
                  v-model="ruleForm.legalAddress"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item label="法人手机号" prop="legalPhone">
                <el-input
                  v-model="ruleForm.legalPhone"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item label="法人邮箱" prop="legalEmail">
                <el-input
                  v-model="ruleForm.legalEmail"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
          </template>
          <el-form-item>
            <div class="btn">
              <!-- <el-button @click="resetForm('ruleForm')">保存草稿</el-button> -->
              <el-button @click="steps -= 1" v-if="steps != 1"
                >上一步</el-button
              >
              <el-button
                type="primary"
                @click="submitForm('ruleForm')"
                v-if="steps == 1"
                >下一步</el-button
              >
              <el-button
                type="primary"
                @click="postForm('ruleForm')"
                v-if="steps == 2"
                >提交</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="contentAfter">
        <div>
          <el-steps direction="vertical" :active="steps">
            <el-step
              title="企业信息"
              description="(对应个人认证的实名认证)"
            ></el-step>
            <el-step
              title="法人信息"
              description="(对应个人认证的基础信息完善)"
            ></el-step>
          </el-steps>
        </div>
      </div>
    </div>

    <div class="footer"></div>
    <!-- 回到顶部 -->
    <el-backtop></el-backtop>
  </div>
</template>
<script>
import { addressJson } from "./yuanshishuju.js";
export default {
  data() {
    return {
      options: addressJson,
      steps: 1,
      ruleForm: {
        type: "",
        certificateType: "",
        certificatePhotoUrl: "",
        certificatePhotoSize: "",
        certificatePhotoType: "image/jpeg",
        registerArea: "",
        registerName: "",
        registerNo: "",
        businessAddress: "",
        businessScope: "",
        companyEmail: "",
        companyPhone: "",
        legalName: "",
        idCardNo: "",
        effectiveDate: "",
        idCardFrontUrl: "",
        idCardFrontSize: "",
        idCardFrontType: "image/jpeg",
        idCardBackUrl: "",
        idCardBackSize: "",
        idCardBackType: "image/jpeg",
        legalAddress: "",
        legalPhone: "",
        legalEmail: "",
      },
      rules: {
        type: [
          {
            required: true,
            message: "请至少选择一个活动类型",
            trigger: "change",
          },
        ],
        certificateType: [
          {
            required: true,
            message: "请选择证书类型",
            trigger: "blur",
          },
        ],
        bankAreaCode: [
          {
            type: "array",
            required: true,
            message: "请选择省市区",
            trigger: "change",
          },
        ],
        registerArea: [
          {
            required: true,
            message: "请填写详细的注册区域",
            trigger: "blur",
          },
        ],
        registerName: [
          {
            required: true,
            message: "请填写企业注册名称",
            trigger: "blur",
          },
        ],
        registerNo: [
          {
            required: true,
            message: "请填写公司注册编号/统一社会信用代码",
            trigger: "blur",
          },
        ],
        businessAddress: [
          {
            required: true,
            message: "请填写公司营业地址",
            trigger: "blur",
          },
        ],
        businessScope: [
          {
            required: true,
            message: "请填写主营业务/业务范围",
            trigger: "blur",
          },
        ],
        companyEmail: [
          {
            required: true,
            message: "请填写企业邮箱",
            trigger: "blur",
          },
        ],
        companyPhone: [
          {
            required: true,
            message: "请填写公司固定电话",
            trigger: "blur",
          },
        ],
        legalName: [
          {
            required: true,
            message: "请填写法人姓名",
            trigger: "blur",
          },
        ],
        idCardNo: [
          {
            required: true,
            message: "请填写法人身份证号",
            trigger: "blur",
          },
        ],
        effectiveDate: [
          {
            required: true,
            message: "请选择身份证有效期",
            trigger: "blur",
          },
        ],
        legalAddress: [
          {
            required: true,
            message: "请填写联系地址",
            trigger: "blur",
          },
        ],
        legalPhone: [
          {
            required: true,
            message: "请填写法人手机号",
            trigger: "blur",
          },
        ],
        legalEmail: [
          {
            required: true,
            message: "请填写法人邮箱",
            trigger: "blur",
          },
        ],
      },
      certificateType: [],
      companyType: [],
      imageUrl: "",
      idFimageUrl: "",
      idBimageUrl: "",
      upActoin: "/api/file/applet/upload",
      upHeaders: {
        Authorization: "Bearer " + window.localStorage.getItem("token"),
      },
      imgData: {},
      bankAreaCode: [],
    };
  },
  computed: {},
  mounted() {
    this.getBasicType();
  },
  methods: {
    getBasicType() {
      console.log(addressJson);
      this.$http.getBasicType().then((res) => {
        this.companyType = res.data.companyType;
        this.certificateType = res.data.certificateType;
        console.log(this.companyType);
      });
    },
    handleAvatarSuccess(res, file) {
      if (res.code == 0) {
        this.$message.success("上传成功");
        this.imageUrl = res.data.fileUrl;
        this.ruleForm.certificatePhotoSize = res.data.fileSize;
        this.ruleForm.certificatePhotoUrl = res.data.fileUrl;
      } else {
        this.$message.error("上传失败");
      }
    },
    beforeAvatarUpload(file) {
      console.log(this.upFormData);
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.steps += 1;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    throttle() {},
    postForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.imageUrl) {
            this.$message.error("请上传企业证件！");
            return;
          }
          if (!this.idFimageUrl) {
            this.$message.error("请上传法人身份证正面！");
            return;
          }
          if (!this.idBimageUrl) {
            this.$message.error("请上传法人身份证背面！");
            return;
          }
          if (!this.bankAreaCode[2]) {
            this.$message.error("请选择企业注册省市区！");
            return;
          }
          this.ruleForm.province = this.bankAreaCode[2].slice(0, 2);
          this.ruleForm.city = this.bankAreaCode[2].slice(0, 4);
          this.ruleForm.county = this.bankAreaCode[2];
          this.$http.companyIdentify(this.ruleForm).then((res) => {
			  if(res.code == 0) {
				this.$router.replace("/agencyRegister/createShop");
			  }
          });
        } else {
          this.$message.error("部分资料填写错误！");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    idFhandleAvatarSuccess(res, file) {
      if (res.code == 0) {
        this.$message.error("上传成功");
        this.idFimageUrl = res.data.fileUrl;
        this.ruleForm.idCardFrontSize = res.data.fileSize;
        this.ruleForm.idCardFrontUrl = res.data.fileUrl;
      } else {
        this.$message.error("上传失败");
      }
    },
    idBhandleAvatarSuccess(res, file) {
      if (res.code == 0) {
        this.$message.error("上传成功");
        this.idBimageUrl = res.data.fileUrl;
        this.ruleForm.idCardBackSize = res.data.fileSize;
        this.ruleForm.idCardBackUrl = res.data.fileUrl;
      } else {
        this.$message.error("上传失败");
      }
    },
    // 个人认证
    userIdentity() {
      window.open("https://china.bmark.cn/authenticationPerson");
    },
  },
};
</script>
<style lang="scss" scoped>
div.page {
  width: 100vw;
  min-height: 100vh;
  background-color: #fff;
  padding: 20px 0;

  div.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40vw;
    min-width: 500px;
    margin: auto;
    padding: 20px 0;

    & > span:first-of-type {
      font-size: 18px;
      color: #303133;
    }

    span {
      font-size: 16px;
      color: #606266;
    }
  }

  div.content {
    width: 40vw;
    min-width: 500px;
    min-height: 100vh;
    margin: auto;
    // padding: 20px;
    background: #ffffff;
    border: 1px solid #dfe2e7;
    position: relative;

    div.content-title {
      padding: 20px;
      font-size: 16px;
      text-align: center;
      background-color: #f5f6f8;
      border-bottom: 1px solid #dfe2e7;
    }

    div.form {
      padding: 20px;
      background: #ffffff;
      .el-radio {
        margin-bottom: 10px;
      }

      div.btn {
        display: flex;
        justify-content: center;
        padding: 40px 0 20px;
        .el-button {
          width: 50%;
        }
      }
    }

    form.el-form {
      padding: 20px;
      background: #f3f6f9;
    }

    div.contentAfter {
      background-color: #ffffff;
      border: 1px solid #dfe2e7;
      width: 250px;
      position: absolute;
      top: 0;
      right: -310px;
      padding: 20px;
    }
  }

  .el-form-item__content {
    display: flex;

    & > div {
      flex: 1;
    }
  }

  .el-step__main {
    margin-bottom: 10px;
  }
  .avatar-uploader {
    ::v-deep .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    ::v-deep .el-upload:hover {
      border-color: #409eff;
    }
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .el-select,
  .el-cascader {
    width: 100%;
  }
}
</style>
